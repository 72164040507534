import React from "react";
import Layout from "../../components/Layout";

class Thanks extends React.Component {
  componentDidMount() {
    if (window.gtag) {
      window.gtag("event", "conversion", {
        send_to: "AW-942236356/HwtYCKmUq5ECEMTFpcED",
      });
    }
  }
  render() {
    return (
      <Layout>
        <section className="section">
          <div className="container">
            <div className="content">
              <h1>Tack!</h1>
              <p>Vi kommer att höra av oss inom 2 arbetsdagar.</p>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default Thanks;
